
import Vue from 'vue';
import * as api from '@/api';
import EditorBar from '@/components/wangEditor.vue';

export default Vue.extend({
  name: 'messageForm',
  components: { EditorBar },
  data() {
    return {
      isClear: false,
      txtRule: false,
      form: {
        messageId: null,
        title: null,
        receiverType: 0,
        content: null,
      } as api.BroadcastMessagesForm,
      rules: {
        title: [
          { required: true, message: '请输入消息标题', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入消息内容', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    messageId(): string {
      return this.$store.state.messageId;
    },
  },
  async created() {
    if (this.messageId) {
      const res = await api.getMessages({ id: this.messageId });
      if (!api.error(res)) {
        this.form = res.data;
      }
    }
  },
  methods: {
    change(val, txtRule) {
      this.txtRule = txtRule;
      this.form.content = val;
    },
    save(formName, send?: boolean) {
      if (this.txtRule) return;
      (this.$refs[formName] as any).validate(async (valid) => {
          if (valid) {
            const res = await api.postBroadcastMessages(this.form);
            if (!api.error(res)) {
              if (send) {
                this.send(res.data.messageId);
                return false;
              }
              this.$message.success(this.form.messageId ? '编辑成功' : '创建成功');
              this.$router.push({
                path: '/operation/messageSet',
              });
            } else {
              this.$message.error(res.error.message);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
          return true;
        });
    },
    async send(id) { // 发送消息
      const res = await api.putMessagesPublish({ id });
      if (!api.error(res)) {
        if (this.form.receiverType === 1) this.$store.dispatch('getUnreadCount');
        this.$message.success('发送成功');
        this.cancel();
      } else {
        this.$message.error(res.error.message);
      }
    },
    cancel() {
      this.$router.push({
        path: '/operation/messageSet',
      });
    },
  },
});

